<template>
  <div>
    <div class="subreseller_wrapper">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div v-if="userType === 'SUBRESELLER'">
              <v-icon color="primary" id="user-type-icon">
                mdi-account-group
              </v-icon>
            </div>
            <div v-else>
              <v-icon color="primary" id="user-type-icon"> mdi-account </v-icon>
            </div>
          </div>
        </template>
        <span>{{ userType }}</span>
      </v-tooltip>
      <div class="profile">
        <!-- Use img if there is image for thumbnail, else use div. -->
        <!-- <img
          src="https://images.unsplash.com/photo-1484186139897-d5fc6b908812?ixlib=rb-0.3.5&s=9358d797b2e1370884aa51b0ab94f706&auto=format&fit=crop&w=200&q=80%20500w"
          class="thumbnail"
        /> -->
        <div :id="thumbnailId" class="thumbnailText">
          {{ subreseller.name.charAt(0) }}
        </div>
        <div class="check">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <i class="fa" :class="statusIcon" v-bind="attrs" v-on="on"></i>
            </template>
            <span>{{ subreseller.status }}</span>
          </v-tooltip>
        </div>
        <h3 class="name">{{ subreseller.name }}</h3>
        <p class="email">{{ subreseller.emailId }}</p>
        <p class="description">MF Credits: {{ creditBalance }}</p>
        <router-link :to="{ path: pathToSubresellerPage }" class="btn">
          View
        </router-link>
      </div>

      <div class="social-icons" :id="statIconsId">
        <div class="icon">
          <a><i class="fa fa-diamond"></i></a>
          <h4 :class="h4Class">
            {{ creditSpent }}
          </h4>
          <p :class="pClass">MF Credit Spent</p>
        </div>

        <div class="icon">
          <a><i class="fa fa-list-ul"></i></a>
          <h4 :class="h4Class">{{ rechargesDone }}</h4>
          <p :class="pClass">Recharges done</p>
        </div>

        <div class="icon">
          <a><i class="fa fa-inr"></i></a>
          <h4 :class="h4Class">{{ moneyEarned }}</h4>
          <p :class="pClass">{{ currency }} Earned</p>
        </div>
      </div>

      <button :id="loadId" class="load" @click="loadStats">
        View Monthly Summary
        <i class="fa fa-angle-down"></i>
      </button>
    </div>
  </div>
</template>

<script>
import "@/assets/css/subreseller_card.scss";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "@/utils/SageUtils";
import * as client from "@/client/webclient";

export default {
  props: {
    subreseller: {},
    index: Number,
    page: Number,
  },
  data() {
    return {
      subresellerStats: {},
      loadId: "loadId" + this.index.toString(),
      h4Class: "h4Class" + this.index.toString(),
      pClass: "pClass" + this.index.toString(),
      statIconsId: "statIconsId" + this.index.toString(),
      thumbnailId: "thumbnailId" + this.index.toString(),
    };
  },
  watch: {
    page: function (_newVal, _oldVal) {
      document.getElementById(this.loadId).style.display = "flex";
      const h4 = document.getElementsByClassName(this.h4Class);
      h4.forEach((header) => {
        header.style.display = "none";
      });
      const p = document.getElementsByClassName(this.pClass);
      p.forEach((paragraph) => {
        paragraph.style.display = "none";
      });
      const icons = document.getElementsByClassName("social-icons");
      icons.forEach((icon) => {
        icon.style.display = "none";
      });
    },
  },
  computed: {
    currency: function () {
      return SageUtils.getCurrency(this.subreseller.countryCode);
    },
    pathToSubresellerPage: function () {
      return "/network/" + this.subreseller.emailId;
    },
    statusIcon: function () {
      switch (this.subreseller.status) {
        case SageConstants.resellerConstants.ACTIVE:
          return "fa-check";
        case SageConstants.resellerConstants.PENDING:
          return "fa-exclamation";
        case SageConstants.resellerConstants.REJECTED:
          return "fa-times";
        case SageConstants.resellerConstants.DEACTIVATED:
          return "fa-ban";
        default:
          return "";
      }
    },
    creditBalance: function () {
      if (this.subreseller && this.subreseller.walletBalance)
        return this.subreseller.walletBalance.toFixed(2);
      return 0;
    },
    creditSpent: function () {
      if (this.subresellerStats && this.subresellerStats.totalMFCredits)
        return this.subresellerStats.totalMFCredits.toFixed(2);
      return 0;
    },
    moneyEarned: function () {
      if (this.subresellerStats && this.subresellerStats.totalMoney)
        return this.subresellerStats.totalMoney.toFixed(2);
      return 0;
    },
    rechargesDone: function () {
      if (this.subresellerStats && this.subresellerStats.count)
        return this.subresellerStats.count;
      return 0;
    },
    userType: function () {
      return this.subreseller.userType;
    },
  },
  methods: {
    async loadStats() {
      try {
        this.subresellerStats = await client.getSubresellerStats(
          this.subreseller.emailId,
          this.$store.state.account.email
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
        return;
      }
      document.getElementById(this.loadId).style.display = "none";
      document.getElementById(this.statIconsId).style.display = "flex";
      const h4 = document.getElementsByClassName(this.h4Class);
      h4.forEach((header) => {
        header.style.display = "block";
      });
      const p = document.getElementsByClassName(this.pClass);
      p.forEach((paragraph) => {
        paragraph.style.display = "block";
      });
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>

<style scoped>
#user-type-icon {
  position: absolute;
  padding-top: 19px;
}
</style>