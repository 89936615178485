<template>
  <div class="form-group col-lg-12 mx-auto mb-0" @click="$emit('onclick')">
    <button
      id="action_button"
      :disabled="disabled"
      type="button"
      class="btn btn-primary btn-lg btn-block"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    text: String,
    disabled: Boolean,
  },
  data() {
    return {};
  },
};
</script>