<template>
  <div>
    <div class="main-content">
      <div class="header" style="padding-bottom: 8rem !important">
        <ResellerHeader :walletCredits="credits" />
      </div>
      <!-- Page content -->
      <div class="container-fluid mt--7">
        <div class="row">
          <!-- Register Sub Resellers Card -->
          <div class="col-xl-4 order-2 mb-5 mb-xl-0">
            <div class="card card-profile shadow">
              <div class="card-body pt-0 pt-md-4">
                <!-- Heading -->
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        card-profile-stats
                        d-flex
                        justify-content-center
                        mt-md-0
                      "
                    >
                      <div>
                        <span class="heading">
                          {{ formHeading }}
                        </span>
                        <span class="description">
                          {{ formDesc }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Form -->
                <div class="text-center">
                  <form id="signup-form" action="#">
                    <div class="row">
                      <!-- Name -->
                      <div class="input-group col-lg-12 mb-4">
                        <div class="input-group-prepend">
                          <span
                            class="
                              input-group-text
                              bg-white
                              px-4
                              border-md border-right-0
                            "
                          >
                            <i class="fa fa-user text-muted"></i>
                          </span>
                        </div>
                        <input
                          v-model="subresellerDetails.name"
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Name"
                          class="form-control bg-white border-left-0 border-md"
                        />
                      </div>
                      <!-- Email ID -->
                      <div class="input-group col-lg-12 mb-4">
                        <div class="input-group-prepend">
                          <span
                            class="
                              input-group-text
                              bg-white
                              px-4
                              border-md border-right-0
                            "
                          >
                            <i class="fa fa-envelope text-muted"></i>
                          </span>
                        </div>
                        <input
                          v-model="subresellerDetails.email"
                          id="email"
                          type="text"
                          name="email"
                          placeholder="Gmail ID"
                          class="form-control bg-white border-left-0 border-md"
                        />
                      </div>
                      <!-- Phone Number -->
                      <div class="input-group col-lg-12 mb-4" id="phone-number">
                        <div class="input-group-prepend">
                          <span
                            class="
                              input-group-text
                              bg-white
                              px-4
                              border-md border-right-0
                            "
                          >
                            <i class="fa fa-phone-square text-muted"></i>
                          </span>
                        </div>
                        <input
                          v-bind:placeholder="subresellerDetails.countryCode"
                          readonly
                          id="countryCode"
                          name="countryCode"
                          style="max-width: 80px"
                          class="
                            custom-select
                            form-control
                            bg-white
                            border-left-0 border-md
                            h-100
                            font-weight-bold
                            text-muted
                          "
                        />
                        <input
                          v-model="subresellerDetails.mobileNumber"
                          id="phoneNumber"
                          type="tel"
                          name="phone"
                          placeholder="Phone Number"
                          @keyup="checkMobileNumber('keyup')"
                          class="
                            form-control
                            bg-white
                            border-md border-left-0
                            pl-3
                          "
                        />
                      </div>
                      <div
                        class="input-group col-lg-12 mb-4 lighten-4"
                        id="type-select"
                      >
                        <v-select
                          label="Select Type"
                          :items="types"
                          v-model="typeSelected"
                          hide-details
                        ></v-select>
                      </div>
                      <!-- Attach KYC -->
                      <!-- <div class="input-group col-lg-12 mb-4" id="attach-kyc">
                        <AttachFile
                          text="Upload KYC Proof Document"
                          @onAttach="kycAttached"
                          :warning="kycNotSubmitted"
                          style="width: auto"
                        />
                      </div> -->

                      <!-- Submit Button -->
                      <ActionButton
                        id="submit-btn"
                        text="Submit"
                        @onclick="submit"
                        class="mb-4"
                        style="flex: 0 0 40%; max-width: 40%"
                      />
                    </div>
                  </form>

                  <hr class="my-4" />
                  <p>
                    By clicking Submit, you agree to the Moonfrog Labs
                    <a
                      href="https://moonfroglabs.com/terms-of-service/"
                      target="_blank"
                      >Terms of Service</a
                    >,
                    <a
                      href="https://moonfroglabs.com/privacy-policy-2/"
                      target="_blank"
                      >Privacy Policy</a
                    >
                    and
                    <router-link
                      :to="{ name: 'distributorTnC' }"
                      target="_blank"
                    >
                      Terms &amp; Conditions</router-link
                    >, that you may be bound by creating this account with
                    Moonfrog Labs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Left Side View -->
          <div class="col-xl-8 order-xl-1" style="padding-bottom: 2rem">
            <!-- Search Bar -->
            <div class="col-lg-12 bg-white rounded rounded-pill shadow-sm mb-4">
              <div class="input-group">
                <input
                  v-model="subresellerName"
                  type="search"
                  :placeholder="searchText"
                  aria-describedby="button-addon1"
                  class="form-control border-0 bg-white"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    @click="fetchSubreseller(1)"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- Subreseller Cards -->
            <div class="subreseller-flex">
              <SubresellerCard
                v-for="(subreseller, index) in subresellersList"
                v-bind:subreseller="subreseller"
                v-bind:index="index"
                v-bind:key="subreseller.id"
                v-bind:page="subresellersCurrentPage"
                class="subreseller-card-component"
              ></SubresellerCard>
            </div>
            <div class="text-center" style="padding-top: 2rem">
              <v-pagination
                v-model="subresellersCurrentPage"
                :length="subresellersTotalPage"
                :total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChangeSubresellers"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import '@/assets/css/home.css'
import "@/assets/css/reseller.css";
import ActionButton from "../components/ActionButton";
import ResellerHeader from "../components/ResellerHeader";
// import AttachFile from "@/components/AttachFile";
import SubresellerCard from "@/components/SubresellerCard";
import * as client from "@/client/webclient";
import * as apiConfig from "../config/api.config";
import * as SageConstants from "../config/SageConstants";

export default {
  name: "myNetwork",
  components: {
    ActionButton,
    ResellerHeader,
    // AttachFile,
    SubresellerCard,
  },
  props: {
    resellerId: String,
  },
  data() {
    return {
      credits: 0,

      types: ["SubReseller", "Retailer"],
      typeSelected: "",
      formDesc:
        "Fill in the details to add a Sub-Reseller / Retailer to your network. Sub-Resellers can onboard Retailers under them.",
      formHeading: "Register Sub Reseller / Retailer",
      searchText: "Enter Sub-Reseller / Retailer Name",

      subresellerDetails: {
        name: "",
        email: "",
        countryCode: "",
        mobileNumber: "",
        // kycObj: null,
      },
      disabled: true,
      // kycNotSubmitted: false,

      subresellerName: "",

      subresellersList: [],
      subresellersTotalPage: 1,
      subresellersCurrentPage: 1,

      isLoading: true,

      resellerData: {},
    };
  },
  created() {
    if (
      this.$store.state.account.userType ===
      SageConstants.userTypeConstants.SUBRESELLER
    ) {
      this.types = ["Retailer"];
      this.formDesc = "Fill in the details to add a Retailer to your network.";
      this.formHeading = "Register Retailer";
      this.searchText = "Enter Retailer Name";
    }
    this.init();
  },
  computed: {},
  methods: {
    async init() {
      try {
        this.credits = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );

        if (!this.$store.state.account.countryCode) {
          const resellerData = await client.getReseller(
            this.$store.state.account.email,
            this.$store.state.account.email
          );
          this.$store.commit("setCountryCode", resellerData.countryCode);
        }
        switch (this.$store.state.account.countryCode) {
          case SageConstants.countryCode.IN:
            this.subresellerDetails.countryCode = "+91";
            break;
          case SageConstants.countryCode.BD:
            this.subresellerDetails.countryCode = "+880";
            break;
          default:
            this.subresellerDetails.countryCode = "+91";
            break;
        }

        await this.getSubresellers();
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    async getSubresellers() {
      this.isLoading = true;
      try {
        const getSubresellersResponse = await client.getSubresellers(
          "",
          this.$store.state.account.email,
          this.subresellersCurrentPage
        );
        this.subresellersList = getSubresellersResponse.subresellersData;
        this.subresellersTotalPage = getSubresellersResponse.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async submit() {
      this.subresellerDetails.name = this.subresellerDetails.name.trim();
      this.subresellerDetails.email = this.subresellerDetails.email
        .toLowerCase()
        .trim();

      /* eslint-disable no-control-regex */
      const re =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      /* eslint-enable no-control-regex */
      if (
        !re.test(this.subresellerDetails.email) ||
        this.subresellerDetails.email.indexOf(" ") > -1
      ) {
        this.$swal
          .fire({
            icon: "error",
            text: "Email ID syntax not valid.",
          })
          .then(() => {
            return;
          });
        return;
      }

      let err = false;
      this.subresellerDetails.name.length === 0
        ? (this.showWarningBorder("name"), (err = true))
        : this.showNormalBorder("name");
      this.subresellerDetails.email.length === 0
        ? (this.showWarningBorder("email"), (err = true))
        : this.showNormalBorder("email");
      this.subresellerDetails.countryCode.length === 0
        ? (this.showWarningBorder("countryCode"), (err = true))
        : this.showNormalBorder("countryCode");
      // this.subresellerDetails.kycObj === null
      //   ? ((this.kycNotSubmitted = true), (err = true))
      //   : (this.kycNotSubmitted = false);
      this.checkMobileNumber("submit")
        ? this.showNormalBorder("phoneNumber")
        : (this.showWarningBorder("phoneNumber"), (err = true));
      this.typeSelected.length === 0
        ? (this.showWarningBackground("type-select"), (err = true))
        : this.showNormalBackground("type-select");

      if (err) return;

      try {
        this.isLoading = true;
        const mfUser = await client.getUser(this.subresellerDetails.email);
        let subresellerDetailsObj = this.convertToFormData();
        if (!mfUser) {
          await client.createReseller(subresellerDetailsObj);
          this.$swal.fire({
            icon: "success",
            text: "Registration Successful.",
          });
          this.subresellerDetails.name = "";
          this.subresellerDetails.mobileNumber = "";
          // } else if (
          //   (mfUser.status === SageConstants.resellerConstants.PENDING ||
          //     mfUser.status === SageConstants.resellerConstants.REJECTED) &&
          //   mfUser.userType === SageConstants.userTypeConstants.SUBRESELLER
          // ) {
          //   const subResellerUser = await client.getReseller(
          //     this.subresellerDetails.email,
          //     this.$store.state.account.email
          //   );
          //   if (
          //     subResellerUser.parentResellerId === this.$store.state.account.email
          //   )
          //     this.$swal
          //       .fire({
          //         icon: "warning",
          //         text: "Email ID already requested for Onboarding. If you proceed, previous request will be overwritten.",
          //         showDenyButton: true,
          //         confirmButtonText: `Proceed`,
          //         denyButtonText: `Change Email ID`,
          //       })
          //       .then(async (result) => {
          //         if (result.isConfirmed) {
          //           await client.createReseller(subresellerDetailsObj);
          //           this.$swal.fire({
          //             icon: "success",
          //             text: "Registration Successful.",
          //           });
          //           this.subresellerDetails.name = "";
          //           this.subresellerDetails.mobileNumber = "";
          //         } else if (result.isDenied) {
          //           return;
          //         }
          //       });
        } else {
          this.$swal
            .fire({
              icon: "error",
              text: "Email ID already Registered.",
            })
            .then(() => {
              return;
            });
        }
      } catch (er) {
        this.showConnectionErrorDialog(er);
      } finally {
        this.isLoading = false;
        this.subresellerDetails.email = "";
      }
    },

    checkMobileNumber(event) {
      let requiredLength;
      switch (this.subresellerDetails.countryCode) {
        case "+91":
        case "+880":
          requiredLength = 10;
          break;
      }
      let mobileNumber = this.subresellerDetails.mobileNumber;
      if (event === "keyup") {
        const num = parseInt(mobileNumber);
        if (
          isNaN(num) ||
          num.toString().length < mobileNumber.length ||
          num.toString().length > requiredLength
        ) {
          mobileNumber = mobileNumber.slice(0, -1);
          document.getElementById("phoneNumber").value = mobileNumber;
          this.subresellerDetails.mobileNumber = mobileNumber;
        }
      } else if (event === "submit") {
        return mobileNumber.length === requiredLength;
      }
    },

    convertToFormData() {
      const phoneNumber =
        this.subresellerDetails.countryCode +
        this.subresellerDetails.mobileNumber;
      const userType =
        this.typeSelected === "SubReseller"
          ? SageConstants.userTypeConstants.SUBRESELLER
          : SageConstants.userTypeConstants.RETAILER;
      // let formData = new FormData();
      // formData.append("emailId", this.subresellerDetails.email);
      // formData.append("userType", SageConstants.userTypeConstants.SUBRESELLER);
      // formData.append("requestorEmail", this.$store.state.account.email);
      // formData.append("access_token", localStorage.getItem("access_token"));
      // formData.append("name", this.subresellerDetails.name);
      // formData.append("status", SageConstants.resellerConstants.PENDING);
      // formData.append("phoneNumber", phoneNumber);
      // formData.append("kycObj", this.subresellerDetails.kycObj);
      // return formData;

      return {
        emailId: this.subresellerDetails.email,
        userType: userType,
        requestorEmail: this.$store.state.account.email,
        access_token: localStorage.getItem("access_token"),
        name: this.subresellerDetails.name,
        status: SageConstants.resellerConstants.PENDING,
        phoneNumber: phoneNumber,
      };
    },

    // kycAttached(fileObj) {
    //   this.subresellerDetails.kycObj = fileObj;
    // },

    async fetchSubreseller(pageNo) {
      //event.preventDefault();
      this.subresellerName = this.subresellerName.trim();
      if (this.subresellerName === "") {
        this.subresellersCurrentPage = 1;
        this.getSubresellers();
        return;
      }
      this.isLoading = true;
      try {
        const getSubresellersResponse = await client.getSubresellers(
          this.subresellerName,
          this.$store.state.account.email,
          pageNo
        );
        this.subresellersList = getSubresellersResponse.subresellersData;
        this.subresellersTotalPage = getSubresellersResponse.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangeSubresellers() {
      this.subresellerName = this.subresellerName.trim();
      if (this.subresellerName === "") {
        this.getSubresellers();
      } else {
        this.fetchSubreseller(this.subresellersCurrentPage);
      }
    },

    showWarningBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-md");
        document.getElementById(id).classList.add("border-danger");
      }
    },
    showNormalBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-danger");
        document.getElementById(id).classList.add("border-md");
      }
    },
    showWarningBackground(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.add("red");
      }
    },
    showNormalBackground(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("red");
      }
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>

<style scoped>
.subreseller-flex {
  display: flex;
  flex-wrap: wrap;
  padding-left: inherit;
  padding-right: inherit;
}

.subreseller-card-component {
  padding: 1rem;
}
@media (max-width: 410px) {
  .subreseller-flex {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
  .subreseller-card-component {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>